import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import $ from 'jquery';

// import '../styles/Navbar.scss';
// import '../styles/Containers.scss';

class Navbar extends Component {
  static propTypes = {
    fixed: PropTypes.bool.isRequired,
    width: PropTypes.number.isRequired,
    location: PropTypes.object.isRequired,
    mainNavHeight: PropTypes.number.isRequired
  };
  constructor() {
    super();
    this.state = {
      fixed: false,
      isOpen: false
    };
    this.handleMobileClick = this.handleMobileClick.bind(this);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.fixed !== prevState.fixed) {
      return { fixed: nextProps.fixed };
    }
    return false;
  }

  handleActive = () => {
    this.setState({ active: !this.state.active });
  };
  handleToTop = () => {
    // const wrapper = document.getElementsByClassName('wrapper')[0];
    // wrapper.scrollTo(0, 0);
    $('.wrapper').animate({ scrollTop: 0 }, 150);
  };

  /* Prvents from rendering a current page */
  handleClick = (e, pathname, currentPath) => {
    if (pathname === currentPath) {
      e.preventDefault();
    } else {
      this.props.resetMenu(false);
      if (pathname.includes('menu') && currentPath.includes('menu')) {
        const menuHeader = document.getElementsByClassName('menuHeader');
        const scrollTo = menuHeader[0].clientHeight - 119;
        if (this.props.menuNavFixed) {
          $('.wrapper').animate({ scrollTop: scrollTo }, 0);
        }
      } else {
        this.handleToTop();
      }
    }
  };
  handleMobileClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  handleMobileLogoClick = (e, pathname, currentPath) => {
    if (this.state.isOpen) this.handleMobileClick();
    if (pathname === '/') {
      e.preventDefault();
    } else {
      this.props.resetMenu(false);
      if (pathname.includes('menu') && currentPath.includes('menu')) {
        const menuHeader = document.getElementsByClassName('menuHeader');
        const scrollTo = menuHeader[0].clientHeight - 119;
        if (this.props.menuNavFixed) {
          $('.wrapper').animate({ scrollTop: scrollTo }, 0);
        }
      } else {
        this.handleToTop();
      }
    }
  };
  handleMobileLinkClick = (e, pathname, currentPath) => {
    // this.setState({ isOpen: !this.state.isOpen });
    this.handleMobileClick();
    if (pathname === currentPath) {
      e.preventDefault();
    } else {
      this.props.resetMenu(false);
      if (pathname.includes('menu') && currentPath.includes('menu')) {
        const menuHeader = document.getElementsByClassName('menuHeader');
        const scrollTo = menuHeader[0].clientHeight - 119;
        if (this.props.menuNavFixed) {
          $('.wrapper').animate({ scrollTop: scrollTo }, 0);
        }
      } else {
        this.handleToTop();
      }
    }
  };
  render() {
    // console.log(this.state.isOpen);
    // console.log(this.props.location.pathname);
    const { width, location, mainNavHeight } = this.props;
    const { fixed, isOpen } = this.state;
    const mobile = width < 767 ? true : false;
    // return this.navbar(width, location, fixed);
    // console.log(mainNavHeight);
    if (mobile)
      return this.mobileNavbar(width, location, fixed, mainNavHeight, isOpen);
    else return this.navbar(width, location, fixed);
  }

  mobileNavbar = (width, location, fixed, mainNavHeight, isOpen) => {
    const style = {
      width: `${width}px`,
      background: isOpen ? '#ffb733' : 'none'
    };
    return (
      <>
        <nav
          style={style}
          className={'mainNav mobileNav ' + (fixed ? 'fixed ' : '')}
        >
          <div className="seoulContainer">
            <ul>
              <li>
                <NavLink
                  to="/"
                  exact
                  onClick={e => {
                    this.handleMobileLogoClick(e, location.pathname, '/');
                  }}
                >
                  <div>
                    <img src="/images/logo-white-h60.png" alt="" />
                    <img src="/images/horizontal-white-h38.png" alt="" />
                  </div>
                </NavLink>
              </li>
            </ul>
            <div
              className={'mobile-nav-button ' + (isOpen ? 'change ' : '')}
              onClick={this.handleMobileClick}
            >
              <div className="bar1" />
              <div className="bar2" />
              <div className="bar3" />
            </div>
          </div>
        </nav>
        <div
          className={'pop-up ' + (isOpen ? 'show ' : '')}
          style={{ top: mainNavHeight }}
        >
          <ul>
            <li>
              <NavLink
                to="/"
                exact
                activeClassName="active"
                onClick={e => {
                  this.handleMobileLinkClick(e, location.pathname, '/');
                }}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/menu/daily-special"
                className="menuLink"
                activeClassName="active"
                onClick={e => {
                  this.handleMobileLinkClick(
                    e,
                    location.pathname,
                    '/menu/daily-special'
                  );
                }}
              >
                Menu
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                activeClassName="active"
                onClick={e => {
                  this.handleMobileLinkClick(e, location.pathname, '/contact');
                }}
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </>
    );
  };

  navbar = (width, location, fixed) => {
    return (
      <nav
        style={{ width: `${width}px` }}
        className={'mainNav ' + (fixed ? 'fixed' : '')}
      >
        <div className="seoulContainer">
          <ul>
            <li>
              <NavLink
                to="/"
                exact
                onClick={e => {
                  this.handleClick(e, location.pathname, '/');
                }}
              >
                <div>
                  <img src="/images/logo-white-h60.png" alt="" />
                  <img src="/images/horizontal-white-h38.png" alt="" />
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/"
                exact
                activeClassName="active"
                onClick={e => {
                  this.handleClick(e, location.pathname, '/');
                }}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/menu/daily-special"
                className="menuLink"
                activeClassName="active"
                onClick={e => {
                  this.handleClick(e, location.pathname, '/menu/daily-special');
                }}
              >
                Menu
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                activeClassName="active"
                onClick={e => {
                  this.handleClick(e, location.pathname, '/contact');
                }}
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    );
  };
}

export default Navbar;
