import React from 'react';
import ReactDOM from 'react-dom';
import 'babel-polyfill';
import Pages from './Pages';
// import './styles/global.scss';
// import * as serviceWorker from './serviceWorker';
import './styles/fonts.scss';
import './styles/var.scss';
import './styles/global.scss';
import './styles/Containers.scss';
import './styles/Footer.scss';
import './styles/headerStyles.scss';
import './styles/LandingHeader.scss';
import './styles/MenuContents.scss';
import './styles/MenuNavbar.scss';
import './styles/Navbar.scss';
import './styles/LoadingSpin.scss';
import './styles/prettyHr.scss';
import './styles/StoreInformation.scss';
import './styles/ToTop.scss';
import './styles/DailySpecials.scss';
import './styles/Testimonials.scss';
import './styles/PageNotFound.scss';
import './styles/LandingCatering.scss';

ReactDOM.render(<Pages />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
