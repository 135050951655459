import React, { Component, lazy, Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
// import { TransitionGroup, CSSTransition } from 'react-transition-group';
import $ from 'jquery';
// import ToTop from './components/ToTop';
// import Catering from './pages/Catering';

// import Home from './pages/Home';
import Navbar from './components/Navbar';
// import Menu from './pages/Menu';
// import Contact from './pages/Contact';
// import PageNotFound from './pages/PageNotFound';
// import Footer from './components/Footer';

import history from './components/history';
import LoadingSpin from './components/LoadingSpin';

const Home = lazy(() => import('./pages/Home'));
// const Navbar = lazy(() => import('./components/Navbar'));
const Menu = lazy(() => import('./pages/Menu'));
const Contact = lazy(() => import('./pages/Contact'));
const PageNotFound = lazy(() => import('./pages/PageNotFound'));
const Footer = lazy(() => import('./components/Footer'));

class Pages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toTop: false,
      fixed: false,
      clientWidth: 0,
      menuNavFixed: false,
      mainNavHeight: 70,
      menuNavOffsetTop: 0
    };
    this.wrapper = React.createRef();

    this.handleScroll = this.handleScroll.bind(this);
    this.menuNavbar = this.menuNavbar.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.resetToTop = this.resetToTop.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.setWidthAndHeight();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  handleResize = () => {
    this.setWidthAndHeight();
  };
  setWidthAndHeight = () => {
    const clientWidth = this.wrapper.current.clientWidth;
    this.setState({ clientWidth });
    if (this.wrapper.current.querySelector('.mainNav')) {
      const mainNavHeight = this.wrapper.current.querySelector('.mainNav')
        .clientHeight;
      this.setState({ mainNavHeight });
    }

    $('.wrapper').animate({ scrollTop: this.wrapper.current.scrollTop - 1 });
  };
  resetMenu = reset => {
    this.setState({ menuNavFixed: reset });
  };
  handleScroll = () => {
    if (this.wrapper.current.querySelector('.menuNav')) {
      const menuHeader = this.wrapper.current.querySelector('.menuHeader');
      const menuNav = this.wrapper.current.querySelector('.menuNav');
      const currentMenuNavOffsetTop =
        menuHeader.clientHeight - menuNav.clientHeight;
      this.setState({ menuNavOffsetTop: currentMenuNavOffsetTop });
    }
    const scrollTop = this.wrapper.current.scrollTop;
    const { menuNavOffsetTop, mainNavHeight } = this.state;
    if (scrollTop > 0) {
      this.setState({ fixed: true });
    } else {
      this.setState({ fixed: false });
    }

    if (this.wrapper.current.querySelector('.menuNav')) {
      if (scrollTop >= menuNavOffsetTop - mainNavHeight) {
        this.setState({ menuNavFixed: true });
      } else {
        this.setState({ menuNavFixed: false });
      }
    }
  };
  menuNavbar = menuNavOffsetTop => {
    this.setState({ menuNavOffsetTop });
  };
  resetToTop = toTop => {
    this.setState({ toTop });
  };
  scrollToTop = () => {
    // this.wrapper.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    $('.wrapper').animate({ scrollTop: 0 }, 300);
  };
  render() {
    const { clientWidth, fixed, menuNavFixed, mainNavHeight } = this.state;
    return (
      <>
        <div
          className="wrapper"
          ref={this.wrapper}
          onScroll={this.handleScroll}
        >
          <Router history={history}>
            <>
              <Navbar
                fixed={fixed}
                menuNavFixed={menuNavFixed}
                width={clientWidth}
                location={history.location}
                resetMenu={this.resetMenu}
                mainNavHeight={mainNavHeight}
              />
              <Suspense fallback={<LoadingSpin />}>
                <>
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={() => (
                        <Home
                          resetTop={this.resetToTop}
                          scrollTop={this.scrollToTop}
                        />
                      )}
                    />
                    <Route
                      path="/menu"
                      render={() => (
                        <Menu
                          fixed={menuNavFixed}
                          width={clientWidth}
                          menuNavbar={this.menuNavbar}
                          mainNavHeight={mainNavHeight}
                          resetMenu={this.resetMenu}
                          resetTop={this.resetToTop}
                          scrollTop={this.scrollToTop}
                          location={history.location}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/contact"
                      render={() => (
                        <Contact
                          resetTop={this.resetToTop}
                          scrollTop={this.scrollToTop}
                        />
                      )}
                    />
                    <Route render={() => <PageNotFound />} />
                  </Switch>
                  <Footer
                    location={history.location}
                    reset={this.resetToTop}
                    totoTop={this.scrollToTop}
                  />
                </>
              </Suspense>
            </>
          </Router>
          {/* <ToTop show={toTop} totoTop={this.scrollToTop} /> */}
        </div>
      </>
    );
  }
}

export default Pages;
